import React from 'react'
import { Link } from 'react-router-dom'
import SecSec from './SecSec'
import { Currency, logo, title } from '../config/config'
import DIvider__img from "../Assets/Imgeas/divider-bg.png"
import { Memory, QuestionMarkSharp, Security, VpnLock } from '@mui/icons-material'
import Fade from 'react-reveal/Fade';
import _fetch from '../config/api'
import Partner from '../HomePage/Partner'


const Index = () => {

    return (
        <>
            <div className='main__sec' id='Eearth__area'>

                <div className='banner_sec_1'>

                    <div className="hero-bg-gradient1"></div>
                    <div className="hero-bg-gradient2"></div>
                    <div className="hero-gradient-ball alltuchtopdown"></div>
                    <div className="ripple-shape">
                        <span className="ripple-1"></span>
                        <span className="ripple-2"></span>
                        <span className="ripple-3"></span>
                        <span className="ripple-4"></span>
                        <span className="ripple-5"></span>
                    </div>

                    <div className='container-main h-100'>
                        <div className='row h-100 align-items-center'>
                            <div className='col-xl-8 col-lg-8 col-12'>
                                <div className='Left_sec'>
                                    <span className='Star__animation'></span>
                                    <Fade top>
                                        <img src={logo} className='short_logo' alt="logo" />
                                        <h5>Welcome to </h5>
                                        <h1 className='left_sec_heading'> <span>{title}</span> <br /> Empowering  Decentralization</h1>

                                        <h6 className='pratag__iner'> At The Rich Crowd, we believe in the power of decentralization to reshape industries, empower communities, and redefine wealth distribution. As a platform dedicated to fostering decentralization, we provide a space where individuals, businesses, and innovators can come together to embrace this transformative concept.

                                        </h6>

                                        <div className='Banner__sec_btn'>
                                            <Link to="/registration" className="btn register-button"> Registration   </Link>
                                            {/* <Link to="#" className="btn Content-button"> Contact Us   </Link> */}
                                        </div>

                                        <div className='Divider__area'>
                                            <img src={DIvider__img} alt="divider__svg" />
                                        </div>
                                    </Fade>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className='Earth__change'>
                    <div className='Earth__change__inner'>
                        <div className='container-main'>
                            {/* <div className='main__sec_sd'> */}
                            <h4 className='heading__card'>Why Choose {title?.toLocaleLowerCase()}?</h4>
                            <div className='row'>
                                <div className='col-12'>
                                    <Fade bottom>

                                        <div className='Card__mad border-0'>
                                            <p>Experience the Future: Join us on a journey into the world of decentralized technologies. We're at the forefront of innovation, offering you access to cutting-edge solutions that leverage blockchain, smart contracts, and decentralized applications.</p>
                                        </div>
                                    </Fade>

                                </div>
                                <div className='col-lg-4 col-md-6 columns'>
                                    <Fade bottom>
                                        <div className='card'>
                                            <span className='card__icon'><Security /></span>
                                            <h3>Security</h3>
                                            <p> Your data security is our top priority. With decentralized architecture, your information remains under your control. No more central points of vulnerability.</p>

                                            {/* <span><a href='#' className='stretched-link'>Become part of the Security</a></span> */}
                                        </div>
                                    </Fade>
                                </div>

                                <div className='col-lg-4 col-md-6 columns'>
                                    <Fade bottom>

                                        <div className='__centerCard card '>
                                            <span className='card__icon'><VpnLock /></span>
                                            <h3> Global Access</h3>
                                            <p>  Unlock a borderless experience. With decentralization, you're not limited by geographic boundaries. Engage with users, content, and services from around the world.</p>

                                            {/* <span><a href='#' className='stretched-link'>Become part of the Global Access</a></span> */}
                                        </div>
                                    </Fade>
                                </div>

                                <div className='col-lg-4 col-md-6 columns'>
                                    <Fade bottom>
                                        <div className='card'>
                                            <span className='card__icon'><Memory /></span>
                                            <h3>Empowerment</h3>
                                            <p> Regain control over your digital presence. On {title?.toLocaleLowerCase()}, you're the master of your data. Choose who accesses your information and how it's used.</p>

                                            {/* <span><a href='#' className='stretched-link'>Become part of the Empowerment</a></span> */}
                                        </div>
                                    </Fade>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>

                <Partner />


                <SecSec />


                <div className="home_faq">
                    <div className='ques_1'><QuestionMarkSharp /></div>
                    <div className='ques_2'><QuestionMarkSharp /></div>
                    <div className='ques_3'><QuestionMarkSharp /></div>
                    <div className="coustom_container">
                        <Fade bottom>
                            <div className='New___contin'>

                                <h2 className='heading__card'>FAQ's</h2>
                                <p>
                                    We are a huge marketplace dedicated to connecting great artists of all {title} with their fans and unique token collectors!
                                </p>

                            </div>
                        </Fade>

                        <div className="faq_accordition">
                            <Fade bottom>
                                <div className='accordion' id='accordionExample'>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="Faq_1">
                                            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#new_1" aria-expanded="true" aria-controls="new_1">
                                                How do I start?
                                            </button>
                                        </h2>
                                        <div id="new_1" class="accordion-collapse collapse show" aria-labelledby="Faq_1" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                Register on the website to gain access to the {title?.toLocaleLowerCase()} Academy and Community.
                                                <ul className='nav'>
                                                    <li> Study the instructions, guides and articles on marketing so you're ready to go. If you have questions and need assistance, get help from other members in the {title?.toLocaleLowerCase()} </li>
                                                    {/* <li>Activate NFT heroes and use them to make progress with your team.</li> */}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingOne">
                                            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#new_2" aria-expanded="false" aria-controls="new_2">
                                                What do I need to get started in {title?.toLocaleLowerCase()}?
                                            </button>
                                        </h2>
                                        <div id="new_2" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                No special knowledge is required. Every participant must have their own smartphone or laptop with an installed app (Trust Wallet, TokenPocket or MetaMask), which has a BNB Smart Chain ({Currency} Chain) cryptocurrency wallet set up. To register, you need to have at least a minimum amount -  {Currency} 35 USDT worth, and some BNB for a blockchain fee.
                                            </div>
                                        </div>
                                    </div>

                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingOne">
                                            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#new_3" aria-expanded="false" aria-controls="new_3">
                                                Where will my profit come from?
                                            </button>
                                        </h2>
                                        <div id="new_3" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                All earnings to your wallet will only come from the activity of downline team. {title?.toLocaleLowerCase()} does not charge any additional fees.
                                            </div>
                                        </div>
                                    </div>



                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingOne">
                                            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#new_4" aria-expanded="false" aria-controls="new_4">
                                                how much does it cost to participate in {title?.toLocaleLowerCase()} ?
                                            </button>
                                        </h2>
                                        <div id="new_4" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                Participation in The Rich Crowd community triggers the initiation of tiers within two marketing programs. Activation commences at a base cost of 35 USDT, excluding minimum fees from the Binance Chain (KSN Token) blockchain, payable in KSN cryptocurrency. The upgrade is not automatic but allows for self-initiation.
                                            </div>
                                        </div>
                                    </div>




                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingOne">
                                            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#new_6" aria-expanded="false" aria-controls="new_6">
                                                Why is {title?.toLocaleLowerCase()} the best alternative to trading?
                                            </button>
                                        </h2>
                                        <div id="new_6" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                The cryptocurrency rate has no effect on the sustainability of the rewards.
                                                The minimum entry threshold is equal to just a couple of cups of coffee.
                                                There is no need to wait for payouts - funds come instantly to your wallet.
                                                Rewards always depend only on the actions of the participant.
                                                The participant can receive the reward indefinitely, as long as he expands his team.
                                            </div>
                                        </div>
                                    </div>

                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingOne">
                                            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#new_7" aria-expanded="false" aria-controls="new_7">
                                                Is {title?.toLocaleLowerCase()} safe?
                                            </button>
                                        </h2>
                                        <div id="new_7" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                The functioning of {title?.toLocaleLowerCase()} is fully blockchain-protected. Participant leaves no personal data and has only his cryptocurrency wallet address connected via WEB 3.0 technology as a login. The entire {title?.toLocaleLowerCase()} infrastructure is built on the operation of tamper-proof smart contracts, and the website is simply a projection of data from the blockchain. It makes no sense to hack into the website.
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </Fade>

                        </div>


                        <div className="doubt_sec">
                            <Fade bottom cascade>
                                <h2>Have Question ? Get in touch!</h2>
                                <p>
                                    We are a huge marketplace dedicated to connecting great artists of all<br />
                                    {title?.toLocaleLowerCase()} with their fans and unique token collectors!
                                </p>
                            </Fade>

                            {/* <Fade bottom>

                                <div className='btn_paddingtop'>
                                    <Link to="/" className='main_btn'><span>Contact us</span></Link>
                                </div>
                            </Fade> */}

                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Index