import React, { useEffect, useState } from 'react';
import userActive from "../Assets/Imgeas/activeUser.png"
import userInactive from "../Assets/Imgeas/InactiveUser.png"
import { api_url, title } from '../config/config';
import _fetch from '../config/api';
import { Link, useParams } from 'react-router-dom';

const TreeX3Pro = () => {

    // const toggleClass = (id) => {
    //     const element = document.getElementById(id);
    //     if (element) {
    //         element.classList.toggle('show__details');
    //     }
    // };
    const { username } = useParams();

    const [data, setdata] = useState({ mainUser: { username: '' }, level1: [], level2: [], level3: [] });
    const [plan,setplan] = useState(1);
    const getData = async () => {
        // e.preventDefault();
        var formData = {};

        const subdata = await _fetch(`${api_url}activation/treeX3Pro?username=${username}&plan=${plan}`, 'GET', formData, {});
        if (subdata.status === "success") {
            localStorage.setItem("api_called", 'false');

            setdata(subdata);
        }
    }
    useEffect(() => {
        // const api_called = localStorage.getItem('api_called');

        // if (!api_called || api_called == 'false') {
        //     localStorage.setItem("api_called", 'true');
        getData();
        // }
    }, [username,plan])

    return (
        <div className='__tree_v1 fxied-width-container '>
            <div className='dashboard-data-container'>

                <div className='row'>
                    <div className='col-12 '>
                        <div className='partner-page-header'>
                            <h1 className='mb-0'> {title} x3 Tree </h1>
                        </div>
                        <label className='plan-label'>Plan</label>
                        <select className='selector-payment' onChange={(e) =>{setplan(e.target.value)}}>
                            <option value={1}> $100</option>
                            <option value={2}> $150</option>
                            <option value={3}> $250</option>
                            <option value={4}> $450</option>
                            <option value={5}> $800</option>
                            <option value={6}> $1500</option>
                            <option value={7}> $2800</option>
                            <option value={8}> $5000</option>
                            <option value={9}> $8000</option>
                            <option value={10}> $12000</option>


                        </select>
                    </div>


                    <div className='col-12 team__sec'>
                        <div className="card mt-2 main-tree">

                            <div className="card-body">

                                <ul className="tree vertical tree_x3">
                                    <li>
                                        <div className="admin">
                                            <div className="admin-img">
                                                <img className="img-sec" src={userActive} alt="active user" />
                                            </div>

                                            <div className="user__name">
                                                <div className="user-img">
                                                    {/* <p> Admin </p> */}
                                                    <p> ID : #{data?.mainUser?.username} </p>
                                                </div>
                                            </div>
                                        </div>

                                        <ul>
                                            <li>
                                                <div className="user-main-sec">
                                                    <Link to={`/tree-x2/${data?.l11}`} target='_blank' className="stretched-link"></Link>
                                                    <div className="user">
                                                        <div className="user-img">
                                                            <img className="img-sec" src={userInactive} alt="userInactive" />
                                                        </div>
                                                    </div>

                                                    <div className="user__name">
                                                        <div className="user-img">
                                                            <p> ID : #{data?.l11}</p>
                                                        </div>

                                                    </div>
                                                </div>
                                                <ul>
                                                    <li>
                                                        <div className="user-main-sec">
                                                            <Link to={`/tree-x2/${data?.l21}`} target='_blank' className="stretched-link"></Link>
                                                            <div className="user">
                                                                <div className="user-img">
                                                                    <img className="img-sec" src={userInactive} alt="userInactive" />
                                                                </div>
                                                            </div>

                                                            <div className="user__name">
                                                                <div className="user-img">
                                                                    <p> ID : #{data?.l21}</p>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <ul>
                                                            <li>
                                                                <div className="user-main-sec">
                                                                    <Link to={`/tree-x2/${data?.l31}`} target='_blank' className="stretched-link"></Link>
                                                                    <div className="user">
                                                                        <div className="user-img">
                                                                            <img className="img-sec" src={userInactive} alt="userInactive" />
                                                                        </div>
                                                                    </div>

                                                                    <div className="user__name">
                                                                        <div className="user-img">
                                                                            <p> ID : #{data?.l31}</p>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className="user-main-sec">
                                                                    <Link to={`/tree-x2/${data?.l32}`} target='_blank' className="stretched-link"></Link>
                                                                    <div className="user">
                                                                        <div className="user-img">
                                                                            <img className="img-sec" src={userInactive} alt="userInactive" />
                                                                        </div>
                                                                    </div>

                                                                    <div className="user__name">
                                                                        <div className="user-img">
                                                                            <p> ID : #{data?.l32}</p>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className="user-main-sec">
                                                                    <Link to={`/tree-x2/${data?.l33}`} target='_blank' className="stretched-link"></Link>
                                                                    <div className="user">
                                                                        <div className="user-img">
                                                                            <img className="img-sec" src={userInactive} alt="userInactive" />
                                                                        </div>
                                                                    </div>

                                                                    <div className="user__name">
                                                                        <div className="user-img">
                                                                            <p> ID : #{data?.l33}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </li>

                                                    <li>
                                                        <div className="user-main-sec">
                                                            <Link to={`/tree-x2/${data?.l22}`} target='_blank' className="stretched-link"></Link>
                                                            <div className="user">
                                                                <div className="user-img">
                                                                    <img className="img-sec" src={userInactive} alt="userInactive" />
                                                                </div>
                                                            </div>

                                                            <div className="user__name">
                                                                <div className="user-img">
                                                                    <p> ID : #{data?.l22}</p>
                                                                </div>
                                                            </div>

                                                        </div>

                                                        <ul>
                                                            <li>
                                                                <div className="user-main-sec">
                                                                    <Link to={`/tree-x2/${data?.l34}`} target='_blank' className="stretched-link"></Link>
                                                                    <div className="user">
                                                                        <div className="user-img">
                                                                            <img className="img-sec" src={userInactive} alt="userInactive" />
                                                                        </div>
                                                                    </div>

                                                                    <div className="user__name">
                                                                        <div className="user-img">
                                                                            <p> ID : #{data?.l34}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className="user-main-sec">
                                                                    <Link to={`/tree-x2/${data?.l35}`} target='_blank' className="stretched-link"></Link>
                                                                    <div className="user">
                                                                        <div className="user-img">
                                                                            <img className="img-sec" src={userInactive} alt="userInactive" />
                                                                        </div>
                                                                    </div>

                                                                    <div className="user__name">
                                                                        <div className="user-img">
                                                                            <p> ID : #{data?.l35}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className="user-main-sec">
                                                                    <Link to={`/tree-x2/${data?.l36}`} target='_blank' className="stretched-link"></Link>
                                                                    <div className="user">
                                                                        <div className="user-img">
                                                                            <img className="img-sec" src={userInactive} alt="userInactive" />
                                                                        </div>
                                                                    </div>

                                                                    <div className="user__name">
                                                                        <div className="user-img">
                                                                            <p> ID : #{data?.l36}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </li>

                                                    <li>
                                                        <div className="user-main-sec">
                                                            <Link to={`/tree-x2/${data?.l23}`} target='_blank' className="stretched-link"></Link>
                                                            <div className="user">
                                                                <div className="user-img">
                                                                    <img className="img-sec" src={userInactive} alt="userInactive" />
                                                                </div>
                                                            </div>

                                                            <div className="user__name">
                                                                <div className="user-img">
                                                                    <p> ID : #{data?.l23}</p>
                                                                </div>
                                                            </div>

                                                        </div>

                                                        <ul>
                                                            <li>
                                                                <div className="user-main-sec">
                                                                    <Link to={`/tree-x2/${data?.l37}`} target='_blank' className="stretched-link"></Link>
                                                                    <div className="user">
                                                                        <div className="user-img">
                                                                            <img className="img-sec" src={userInactive} alt="userInactive" />
                                                                        </div>
                                                                    </div>

                                                                    <div className="user__name">
                                                                        <div className="user-img">
                                                                            <p> ID : #{data?.l37}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className="user-main-sec">
                                                                    <Link to={`/tree-x2/${data?.l38}`} target='_blank' className="stretched-link"></Link>
                                                                    <div className="user">
                                                                        <div className="user-img">
                                                                            <img className="img-sec" src={userInactive} alt="userInactive" />
                                                                        </div>
                                                                    </div>

                                                                    <div className="user__name">
                                                                        <div className="user-img">
                                                                            <p> ID : #{data?.l38}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className="user-main-sec">
                                                                    <Link to={`/tree-x2/${data?.l39}`} target='_blank' className="stretched-link"></Link>
                                                                    <div className="user">
                                                                        <div className="user-img">
                                                                            <img className="img-sec" src={userInactive} alt="userInactive" />
                                                                        </div>
                                                                    </div>

                                                                    <div className="user__name">
                                                                        <div className="user-img">
                                                                            <p> ID : #{data?.l39}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </li>

                                                </ul>
                                            </li>

                                            <li>
                                                <div className="user-main-sec">
                                                    <Link to={`/tree-x2/${data?.l12}`} target='_blank' className="stretched-link"></Link>
                                                    <div className="user">
                                                        <div className="user-img">
                                                            <img className="img-sec" src={userInactive} alt="userInactive" />
                                                        </div>
                                                    </div>

                                                    <div className="user__name">
                                                        <div className="user-img">
                                                            <p> ID : #{data?.l12} </p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <ul>
                                                    <li>
                                                        <div className="user-main-sec">
                                                            <Link to={`/tree-x2/${data?.l24}`} target='_blank' className="stretched-link"></Link>
                                                            <div className="user">
                                                                <div className="user-img">
                                                                    <img className="img-sec" src={userInactive} alt="userInactive" />
                                                                </div>
                                                            </div>

                                                            <div className="user__name">
                                                                <div className="user-img">
                                                                    <p> ID : #{data?.l24} </p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <ul>
                                                            <li>                                                        <div className="user-main-sec">
                                                                <Link to={`/tree-x2/${data?.l310}`} target='_blank' className="stretched-link"></Link>
                                                                <div className="user">
                                                                    <div className="user-img">
                                                                        <img className="img-sec" src={userInactive} alt="userInactive" />
                                                                    </div>
                                                                </div>

                                                                <div className="user__name">
                                                                    <div className="user-img">
                                                                        <p> ID : #{data?.l310} </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            </li>

                                                            <li>                                                        <div className="user-main-sec">
                                                                <Link to={`/tree-x2/${data?.l311}`} target='_blank' className="stretched-link"></Link>
                                                                <div className="user">
                                                                    <div className="user-img">
                                                                        <img className="img-sec" src={userInactive} alt="userInactive" />
                                                                    </div>
                                                                </div>

                                                                <div className="user__name">
                                                                    <div className="user-img">
                                                                        <p> ID : #{data?.l311} </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            </li>

                                                            <li>
                                                                <div className="user-main-sec">
                                                                    <Link to={`/tree-x2/${data?.l312}`} target='_blank' className="stretched-link"></Link>
                                                                    <div className="user">
                                                                        <div className="user-img">
                                                                            <img className="img-sec" src={userInactive} alt="userInactive" />
                                                                        </div>
                                                                    </div>

                                                                    <div className="user__name">
                                                                        <div className="user-img">
                                                                            <p> ID : #{data?.l312} </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </li>

                                                    <li>
                                                        <div className="user-main-sec">
                                                            <Link to={`/tree-x2/${data?.l25}`} target='_blank' className="stretched-link"></Link>
                                                            <div className="user">
                                                                <div className="user-img">
                                                                    <img className="img-sec" src={userInactive} alt="userInactive" />
                                                                </div>
                                                            </div>

                                                            <div className="user__name">
                                                                <div className="user-img">
                                                                    <p> ID : #{data?.l25}</p>
                                                                </div>
                                                            </div>


                                                        </div>

                                                        <ul>
                                                            <li>
                                                                <div className="user-main-sec">
                                                                    <Link to={`/tree-x2/${data?.l313}`} target='_blank' className="stretched-link"></Link>
                                                                    <div className="user">
                                                                        <div className="user-img">
                                                                            <img className="img-sec" src={userInactive} alt="userInactive" />                                                                </div>
                                                                    </div>

                                                                    <div className="user__name">
                                                                        <div className="user-img">
                                                                            <p> ID : #{data?.l313} </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className="user-main-sec">
                                                                    <Link to={`/tree-x2/${data?.l314}`} target='_blank' className="stretched-link"></Link>
                                                                    <div className="user">
                                                                        <div className="user-img">
                                                                            <img className="img-sec" src={userInactive} alt="userInactive" />                                                                </div>
                                                                    </div>

                                                                    <div className="user__name">
                                                                        <div className="user-img">
                                                                            <p> ID : #{data?.l314} </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className="user-main-sec">
                                                                    <Link to={`/tree-x2/${data?.l315}`} target='_blank' className="stretched-link"></Link>
                                                                    <div className="user">
                                                                        <div className="user-img">
                                                                            <img className="img-sec" src={userInactive} alt="userInactive" />
                                                                        </div>
                                                                    </div>

                                                                    <div className="user__name">
                                                                        <div className="user-img">
                                                                            <p> ID : #{data?.l315} </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </li>

                                                    <li>
                                                        <div className="user-main-sec">
                                                            <Link to={`/tree-x2/${data?.l26}`} target='_blank' className="stretched-link"></Link>
                                                            <div className="user">
                                                                <div className="user-img">
                                                                    <img className="img-sec" src={userInactive} alt="userInactive" />
                                                                </div>
                                                            </div>

                                                            <div className="user__name">
                                                                <div className="user-img">
                                                                    <p> ID : #{data?.l26}</p>
                                                                </div>
                                                            </div>


                                                        </div>

                                                        <ul>
                                                            <li>
                                                                <div className="user-main-sec">
                                                                    <Link to={`/tree-x2/${data?.l316}`} target='_blank' className="stretched-link"></Link>
                                                                    <div className="user">
                                                                        <div className="user-img">
                                                                            <img className="img-sec" src={userInactive} alt="userInactive" />                                                                </div>
                                                                    </div>

                                                                    <div className="user__name">
                                                                        <div className="user-img">
                                                                            <p> ID : #{data?.l316} </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className="user-main-sec">
                                                                    <Link to={`/tree-x2/${data?.l317}`} target='_blank' className="stretched-link"></Link>
                                                                    <div className="user">
                                                                        <div className="user-img">
                                                                            <img className="img-sec" src={userInactive} alt="userInactive" />
                                                                        </div>
                                                                    </div>

                                                                    <div className="user__name">
                                                                        <div className="user-img">
                                                                            <p> ID : #{data?.l317} </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className="user-main-sec">
                                                                    <Link to={`/tree-x2/${data?.l318}`} target='_blank' className="stretched-link"></Link>
                                                                    <div className="user">
                                                                        <div className="user-img">
                                                                            <img className="img-sec" src={userInactive} alt="userInactive" />
                                                                        </div>
                                                                    </div>

                                                                    <div className="user__name">
                                                                        <div className="user-img">
                                                                            <p> ID : #{data?.l318} </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>

                                            <li>
                                                <div className="user-main-sec">
                                                    <Link to={`/tree-x2/${data?.l13}`} target='_blank' className="stretched-link"></Link>
                                                    <div className="user">
                                                        <div className="user-img">
                                                            <img className="img-sec" src={userInactive} alt="userInactive" />
                                                        </div>
                                                    </div>

                                                    <div className="user__name">
                                                        <div className="user-img">
                                                            <p> ID : #{data?.l13}</p>
                                                        </div>

                                                    </div>
                                                </div>
                                                <ul>
                                                    <li>
                                                        <div className="user-main-sec">
                                                            <Link to={`/tree-x2/${data?.l27}`} target='_blank' className="stretched-link"></Link>
                                                            <div className="user">
                                                                <div className="user-img">
                                                                    <img className="img-sec" src={userInactive} alt="userInactive" />
                                                                </div>
                                                            </div>

                                                            <div className="user__name">
                                                                <div className="user-img">
                                                                    <p> ID : #{data?.l27}</p>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <ul>
                                                            <li>
                                                                <div className="user-main-sec">
                                                                    <Link to={`/tree-x2/${data?.l319}`} target='_blank' className="stretched-link"></Link>
                                                                    <div className="user">
                                                                        <div className="user-img">
                                                                            <img className="img-sec" src={userInactive} alt="userInactive" />
                                                                        </div>
                                                                    </div>

                                                                    <div className="user__name">
                                                                        <div className="user-img">
                                                                            <p> ID : #{data?.l319}</p>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className="user-main-sec">
                                                                    <Link to={`/tree-x2/${data?.l320}`} target='_blank' className="stretched-link"></Link>
                                                                    <div className="user">
                                                                        <div className="user-img">
                                                                            <img className="img-sec" src={userInactive} alt="userInactive" />
                                                                        </div>
                                                                    </div>

                                                                    <div className="user__name">
                                                                        <div className="user-img">
                                                                            <p> ID : #{data?.l320}</p>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className="user-main-sec">
                                                                    <Link to={`/tree-x2/${data?.l321}`} target='_blank' className="stretched-link"></Link>
                                                                    <div className="user">
                                                                        <div className="user-img">
                                                                            <img className="img-sec" src={userInactive} alt="userInactive" />
                                                                        </div>
                                                                    </div>

                                                                    <div className="user__name">
                                                                        <div className="user-img">
                                                                            <p> ID : #{data?.l321}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </li>

                                                    <li>
                                                        <div className="user-main-sec">
                                                            <Link to={`/tree-x2/${data?.l28}`} target='_blank' className="stretched-link"></Link>
                                                            <div className="user">
                                                                <div className="user-img">
                                                                    <img className="img-sec" src={userInactive} alt="userInactive" />
                                                                </div>
                                                            </div>

                                                            <div className="user__name">
                                                                <div className="user-img">
                                                                    <p> ID : #{data?.l28}</p>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <ul>
                                                            <li>
                                                                <div className="user-main-sec">
                                                                    <Link to={`/tree-x2/${data?.l322}`} target='_blank' className="stretched-link"></Link>
                                                                    <div className="user">
                                                                        <div className="user-img">
                                                                            <img className="img-sec" src={userInactive} alt="userInactive" />
                                                                        </div>
                                                                    </div>

                                                                    <div className="user__name">
                                                                        <div className="user-img">
                                                                            <p> ID : #{data?.l322}</p>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className="user-main-sec">
                                                                    <Link to={`/tree-x2/${data?.l323}`} target='_blank' className="stretched-link"></Link>
                                                                    <div className="user">
                                                                        <div className="user-img">
                                                                            <img className="img-sec" src={userInactive} alt="userInactive" />
                                                                        </div>
                                                                    </div>

                                                                    <div className="user__name">
                                                                        <div className="user-img">
                                                                            <p> ID : #{data?.l323}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className="user-main-sec">
                                                                    <Link to={`/tree-x2/${data?.l324}`} target='_blank' className="stretched-link"></Link>
                                                                    <div className="user">
                                                                        <div className="user-img">
                                                                            <img className="img-sec" src={userInactive} alt="userInactive" />
                                                                        </div>
                                                                    </div>

                                                                    <div className="user__name">
                                                                        <div className="user-img">
                                                                            <p> ID : #{data?.l324}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>

                                                        </ul>
                                                    </li>

                                                    <li>
                                                        <div className="user-main-sec">
                                                            <Link to={`/tree-x2/${data?.l29}`} target='_blank' className="stretched-link"></Link>
                                                            <div className="user">
                                                                <div className="user-img">
                                                                    <img className="img-sec" src={userInactive} alt="userInactive" />
                                                                </div>
                                                            </div>

                                                            <div className="user__name">
                                                                <div className="user-img">
                                                                    <p> ID : #{data?.l29}</p>
                                                                </div>
                                                            </div>

                                                        </div>

                                                        <ul>
                                                            <li>
                                                                <div className="user-main-sec">
                                                                    <Link to={`/tree-x2/${data?.l325}`} target='_blank' className="stretched-link"></Link>
                                                                    <div className="user">
                                                                        <div className="user-img">
                                                                            <img className="img-sec" src={userInactive} alt="userInactive" />
                                                                        </div>
                                                                    </div>

                                                                    <div className="user__name">
                                                                        <div className="user-img">
                                                                            <p> ID : #{data?.l325}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className="user-main-sec">
                                                                    <Link to={`/tree-x2/${data?.l326}`} target='_blank' className="stretched-link"></Link>
                                                                    <div className="user">
                                                                        <div className="user-img">
                                                                            <img className="img-sec" src={userInactive} alt="userInactive" />
                                                                        </div>
                                                                    </div>

                                                                    <div className="user__name">
                                                                        <div className="user-img">
                                                                            <p> ID : #{data?.l326}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className="user-main-sec">
                                                                    <Link to={`/tree-x2/${data?.l327}`} target='_blank' className="stretched-link"></Link>
                                                                    <div className="user">
                                                                        <div className="user-img">
                                                                            <img className="img-sec" src={userInactive} alt="userInactive" />
                                                                        </div>
                                                                    </div>

                                                                    <div className="user__name">
                                                                        <div className="user-img">
                                                                            <p> ID : #{data?.l327}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>

                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>

                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default TreeX3Pro;
